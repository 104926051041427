exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-brillen-tsx": () => import("./../../../src/pages/brillen.tsx" /* webpackChunkName: "component---src-pages-brillen-tsx" */),
  "component---src-pages-datenschutzerklaerung-tsx": () => import("./../../../src/pages/datenschutzerklaerung.tsx" /* webpackChunkName: "component---src-pages-datenschutzerklaerung-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-horgerate-tsx": () => import("./../../../src/pages/hörgeräte.tsx" /* webpackChunkName: "component---src-pages-horgerate-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-karriere-tsx": () => import("./../../../src/pages/karriere.tsx" /* webpackChunkName: "component---src-pages-karriere-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-kontaktlinsen-tsx": () => import("./../../../src/pages/kontaktlinsen.tsx" /* webpackChunkName: "component---src-pages-kontaktlinsen-tsx" */),
  "component---src-pages-marken-tsx": () => import("./../../../src/pages/marken.tsx" /* webpackChunkName: "component---src-pages-marken-tsx" */),
  "component---src-pages-philosophie-tsx": () => import("./../../../src/pages/philosophie.tsx" /* webpackChunkName: "component---src-pages-philosophie-tsx" */),
  "component---src-pages-service-tsx": () => import("./../../../src/pages/service.tsx" /* webpackChunkName: "component---src-pages-service-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-werkstatt-tsx": () => import("./../../../src/pages/werkstatt.tsx" /* webpackChunkName: "component---src-pages-werkstatt-tsx" */),
  "component---src-pages-zeiss-tsx": () => import("./../../../src/pages/zeiss.tsx" /* webpackChunkName: "component---src-pages-zeiss-tsx" */)
}

